<template>
   <div>
      <page-header pageTitle="SITUATIONS.INDEX_TITLE"/>

      <list-component :items="situationsIndex" :haveOptions="false" v-if="situationsIndex">
         <template v-slot:principal-info="{items}">
            <div class="row gutters mt-2 ml-1 mr-custom">
               <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 text-center" :style="size()">
                  <div class="card" :style="{'background-color': items.iten.backgroud_collor}">
                     <h3 class="mt-1" :style="{'color': items.iten.text_collor}">000{{ items.key + 1 }}</h3>
                     <p :style="{'color': items.iten.text_collor}">999 m²</p>
                     <p class="font-weight-bold" :style="{'color': items.iten.text_collor}">R$ 50.000,00</p>
                  </div>
               </div>
               <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12" v-if="hasShowPermission">
                  <label for="front_size">{{ t('PROPERTIES.DESCRIPTION') }}: </label>
                  <input type="text" class="form-control" v-model="items.iten.name" disabled>
               </div>
               <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 " v-if="hasShowPermission">
                  <label for="front_size">{{ t('SITUATIONS.BACKGROUND_COLOR') }}: </label>
                  <input type="color" id="colorpicker" class="form-control" v-model="items.iten.backgroud_collor"
                         @focus="setOriginalSituation(items.iten.backgroud_collor)"
                         @change="updateSituation(items.iten.backgroud_collor, items.iten, items.key, 'SITUATIONS.BACKGROUND_COLOR')"
                         :disabled="!hasUpdatePermission">
               </div>
               <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12" v-if="hasShowPermission">
                  <label for="front_size">{{ t('SITUATIONS.TEXT_COLOR') }}: </label>
                  <input type="color" id="colorpicker" class="form-control" v-model="items.iten.text_collor"
                         @focus="setOriginalSituation(items.iten.text_collor)"
                         @change="updateSituation(items.iten.text_collor, items.iten, items.key, 'SITUATIONS.TEXT_COLOR')"
                         :disabled="!hasUpdatePermission">
               </div>
            </div>
         </template>
      </list-component>

      <div class="card" v-if="!situationsIndex">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('SITUATIONS.INDEX_TITLE'), os: 'as'}) }}</h5>
            </div>
         </div>
      </div>

   </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Properties from "@/services/Properties";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import pageHeader from "@/components/layouts/pageHeader";
import ListComponent from "@/components/layouts/listComponent.vue";

export default {
   name: 'indexSituation',
   mixins: [Validate, Permission],

   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },

   data() {
      return {
         situationsIndex: [],
         originalSituation: {},
         hasShowPermission: false,
         hasUpdatePermission: false,
      }
   },

   components: {
      pageHeader,
      ListComponent
   },

   mounted() {
      this.$store.commit('changeLoading', true);
      this.indexSituation();
      this.startPermissons();
   },

   methods: {
      async indexSituation() {
         await Properties.indexSituation().then(resp => {
            this.situationsIndex = resp.data.data;
            this.$store.commit('changeLoading', false);
         }).catch(error => {
            this.errorMsg(error);
            this.$store.commit('changeLoading', false);
         });
      },

      setOriginalSituation(situation) {
         this.originalSituation = situation;
      },

      startPermissons() {
         this.hasShowPermission = this.validatePermissions('Lot:show');
         this.hasUpdatePermission = this.validatePermissions('Lot:update');
      },

      updateSituation(target, situation, key, msg) {
         if (target != this.originalSituation) {
            Properties.updateSituation(situation).then(resp => {
               this.situationsIndex[key] = resp.data;
               this.toast.success(this.t('GENERAL.MSG.PLACEHOLDER_UPDATE_SUCCESS', {name: this.t(msg)}));
            }).catch(error => {
               this.errorMsg(error);
            });
         }
      },

      size() {
         if (this.widthPhone(768)) {
            return 'max-width: 99%; margin: 0rem 1%';
         } else {
            return 'max-width: 170px;';
         }
      }
   },
}
</script>
<style lang="css">
.custon-padding {
   padding: 1rem !important;
}

.mr-custom {
   margin-right: 0px !important;
}
</style>